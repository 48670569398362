import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import citusImg from "../../../components/database/images/citus.png";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/citus_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/citus/";

const Citus = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant GraphQL APIs on Citus Hyperscale Postgres | Hasura REST API"
      description="Hasura connects to existing Citus Hyperscale Postgres database and gives instant GraphQL CRUD APIs."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST API for Citus Hyperscale Postgres"
      desc="Hasura supports Citus Hyperscale Postgres and gives instant REST APIs"
      btnLink="https://cloud.hasura.io/signup?pg=citus&plcmt=body&cta=try-it-out-in-30-seconds&tech=default"
      btnText="Try it Out in 30s"
      imgSrc={citusImg}
      linkUrl="https://hasura.io/docs/latest/graphql/core/databases/citus-hyperscale-postgres/index.html"
      linkText="Check out the docs here"
    />
  </Layout>
);

export default Citus;
