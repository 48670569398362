import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import StyledButton from "../../shared/StyledButton";

import { Icon } from "../../../globals/icons";
import { StyledTitle4, StyledDesc1 } from "../../shared/StyledTypography";
import { COLORS } from "../../../globals/designSystem";
import { flexCenter } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const StyledDbTopBannerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  .borderRight {
    border-right: 1.5px solid ${COLORS.grey_20};
  }
  .wd75 {
    width: 75%;
  }
  .buttonWrapper {
    padding-top: 16px;
  }
  .pt24 {
    padding-top: 24px;
  }
  .pt8 {
    padding-top: 8px;
  }
  .dbBrandImg {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 180px;
    img {
      width: 290px;
    }
  }
  ${mq.between("md", "lg")} {
    .wd75 {
      width: 90%;
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    .borderRight {
      border-right: 0;
    }
    .wd75 {
      width: 100%;
    }
    .dbBrandImg {
      min-height: unset;
      justify-content: flex-start;
    }
  }
`;

const TopBanner = ({ title, desc, previewType, btnText, btnLink, imgSrc, linkUrl, linkText, secondaryLinkUrl, secondaryLinkText }) => {
  return (
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledDbTopBannerWrapper>
          <div className="borderRight">
            <div className="wd75">
              {previewType ? (
                <StyledDesc1 variant="grey_80" fontWeight="font_500">
                  {previewType}
                </StyledDesc1>
              ) : null}
              <StyledTitle4 as="h1" paddingBottom="pb24">
                {title}
              </StyledTitle4>
              <StyledDesc1>{desc}</StyledDesc1>
              <div className="buttonWrapper">
                <a href={btnLink}>
                  <StyledButton variant="blue" size="md">
                    {btnText}
                  </StyledButton>
                </a>
              </div>
              {linkUrl ? (
                <StyledDesc1 className="pt24">
                  <a href={linkUrl} css={flexCenter}>
                    {linkText}
                    <Icon className="arrow" variant="arrow_right" color="sky_80" size="sm" />
                  </a>
                </StyledDesc1>
              ) : null}
              {
                secondaryLinkUrl && (
                  <StyledDesc1 className="pt8">
                    <a href={secondaryLinkUrl} css={flexCenter}>
                      {secondaryLinkText}
                      <Icon className="arrow" variant="arrow_right" color="sky_80" size="sm" />
                    </a>
                  </StyledDesc1>
                )
              }
            </div>
          </div>
          <div className="dbBrandImg">
            <img src={imgSrc} alt={title} className="db-details-page-brand-img" />
          </div>
        </StyledDbTopBannerWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default TopBanner;
